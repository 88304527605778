<template>
  <div>

    <patient-therapy-form v-model="showAddTherapyForm" @after-save="onAfterSaveTherapy" :therapy="newPatientTherapy" ></patient-therapy-form>

    <h4>Therapie</h4>
    <hr/>
    <el-row>
      <el-col :span="7">
        <h6>1. Medikament</h6>
        <el-select size="medium" class="w-100" v-model="form.drug1" filterable>
          <el-option :value="null">Keine Auswahl</el-option>
          <el-option
              v-for="item in allDrugs"
              :key="item.id"
              :label="(item.title + (item.company ? ', ' + item.company : ''))"
              :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :offset="1" :span="7">
        <h6>2. Medikament</h6>
        <el-select size="medium" class="w-100" v-model="form.drug2" filterable>
          <el-option :value="null">Keine Auswahl</el-option>
          <el-option
            v-for="item in allDrugs"
            :key="item.id"
            :label="(item.title + (item.company ? ', ' + item.company : ''))"
            :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :offset="1" :span="7">
        <h6>3. Medikament</h6>
        <el-select size="medium" class="w-100" v-model="form.drug3" filterable>
          <el-option :value="null">Keine Auswahl</el-option>
          <el-option
            v-for="item in allDrugs"
            :key="item.id"
            :label="(item.title + (item.company ? ', ' + item.company : ''))"
            :value="item.id">
          </el-option>
        </el-select>
      </el-col>
    </el-row>

    <hr/>

    <h5>Protokoll auswählen</h5>

    <el-row>
      <el-col :span="24">

        <el-table
            :data="protocols"
            style="width: 100%"
            highlight-current-row
            @row-click="onProtocolRowClick"
            height="500"
            stripe>
          <el-table-column
              label="Titel"
              prop="title">
          </el-table-column>
          <el-table-column
            width="500"
            label="Beschreibung"
            prop="description">
          </el-table-column>
          <el-table-column
            label="KZ Titel"
            prop="kz_title">
          </el-table-column>
          <el-table-column
            label="Spezial"
            prop="special">
          </el-table-column>
          <el-table-column
            label="Oral"
            prop="oral">
          </el-table-column>
        </el-table>

      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import PatientTherapyForm from "@/components/patient/PatientTherapyForm";

export default {
  name: "PatientTherapyFromDrug",
  props: [],
  components: {
    PatientTherapyForm
  },
  data() {
    return {
      newPatientTherapy: {
        meta: {
          therapy: {}
        }
      },
      showAddTherapyForm: false,
      currentProtocol: null,
      allDrugs: [],
      form: {
        drug1: null,
        drug2: null,
        drug3: null
      }
    }
  },
  mounted: async function () {

    await this.getDrugs()
    this.allDrugs = {...this.drugs}
  },
  computed: {
    ...mapGetters('patients', ['currentPatient', 'fullInfo']),
    ...mapState('protocols', ['protocols']),
    ...mapState('drugs', ['drugs']),
  },
  watch: {
    'form.drug1': async function () {
      await this.getProtocols(this.form)
    },
    'form.drug2': async function () {
      await this.getProtocols(this.form)

    },
    'form.drug3':async function () {
      await this.getProtocols(this.form)
    }
  },
  methods: {
    ...mapActions('drugs', ['getDrugs']),
    ...mapActions('protocols', ['getProtocols']),
    onProtocolRowClick(row) {
      this.currentProtocol = row
      let newPatientTherapy = {}
      newPatientTherapy.meta = {}
      newPatientTherapy.meta.patient = this.currentPatient
      newPatientTherapy.meta.therapy = null
      newPatientTherapy.meta.protocol = row
      newPatientTherapy.patient_id = this.currentPatient.id
      newPatientTherapy.therapy_id = null
      newPatientTherapy.protocol_id = row.id
      newPatientTherapy.date_start = new Date()
      this.newPatientTherapy = newPatientTherapy
      this.showAddTherapyForm = true
    },
    onAfterSaveTherapy() {
    }
  }
}
</script>
<style>
.text-dacy, .el-collapse-item__header {
  color:#cf9236;
}

.el-collapse-item .el-collapse-item .el-collapse-item__header {
  color:#00A;
  font-size:15px;
}

.el-collapse-item__header {
  font-size:16px;
}
</style>
